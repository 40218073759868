<template>
    <aside>
      <div class="logo"><router-link to="/"><img src="@/assets/images/logo.png"/></router-link></div>
      <nav class="nav">
          <ul>
          <li><a href="#" v-bind:class="{ done : step > 2 , current : step == 1 || step == 2 }">
              <svg class="default">
                  <use xlink:href="@/assets/images/sprite.svg#right-arrow"></use>
              </svg>
              <svg class="done-icon">
                  <use xlink:href="@/assets/images/sprite.svg#icons8-checked"></use>
              </svg>Zusatzoptionen</a></li>
          <li><a href="#" v-bind:class="{ done : step > 3 , current : step == 3 }">
              <svg class="default">
                  <use xlink:href="@/assets/images/sprite.svg#right-arrow"></use>
              </svg>
              <svg class="done-icon">
                  <use xlink:href="@/assets/images/sprite.svg#icons8-checked"></use>
              </svg>Scheidungsantrag</a></li>
          <li><a href="#" v-bind:class="{ done : step > 4 , current : step == 4 }">
              <svg class="default">
                  <use xlink:href="@/assets/images/sprite.svg#right-arrow"></use>
              </svg>
              <svg class="done-icon">
                  <use xlink:href="@/assets/images/sprite.svg#icons8-checked"></use>
              </svg>Zustimmung</a></li>
          <li><a href="#" v-bind:class="{ done : step > 5 , current : step == 5 }">
              <svg class="default">
                  <use xlink:href="@/assets/images/sprite.svg#right-arrow"></use>
              </svg>
              <svg class="done-icon">
                  <use xlink:href="@/assets/images/sprite.svg#icons8-checked"></use>
              </svg>Daten 1</a></li>
          <li><a href="#" v-bind:class="{ done : step > 6 , current : step == 6 }">
              <svg class="default">
                  <use xlink:href="@/assets/images/sprite.svg#right-arrow"></use>
              </svg>
              <svg class="done-icon">
                  <use xlink:href="@/assets/images/sprite.svg#icons8-checked"></use>
              </svg>Daten 2</a></li>
            <li><a href="#" v-bind:class="{ done : step > 7 , current : step == 7 }">
              <svg class="default">
                  <use xlink:href="@/assets/images/sprite.svg#right-arrow"></use>
              </svg>
              <svg class="done-icon">
                  <use xlink:href="@/assets/images/sprite.svg#icons8-checked"></use>
              </svg>Heirat & Trennung</a>
            </li>
            <li><a href="#" v-bind:class="{ done : step > 8 , current : step == 8 }">
              <svg class="default">
                  <use xlink:href="@/assets/images/sprite.svg#right-arrow"></use>
              </svg>
              <svg class="done-icon">
                  <use xlink:href="@/assets/images/sprite.svg#icons8-checked"></use>
              </svg>Wohnung</a>
            </li>
            <li><a href="#" v-bind:class="{ done : step > 10 , current : step == 9 || step == 10 }">
              <svg class="default">
                  <use xlink:href="@/assets/images/sprite.svg#right-arrow"></use>
              </svg>
              <svg class="done-icon">
                  <use xlink:href="@/assets/images/sprite.svg#icons8-checked"></use>
              </svg>Kinder</a>
            </li>
          <li><a href="#" v-bind:class="{ done : step > 11 , current : step == 11 }">
              <svg class="default">
                  <use xlink:href="@/assets/images/sprite.svg#right-arrow"></use>
              </svg>
              <svg class="done-icon">
                  <use xlink:href="@/assets/images/sprite.svg#icons8-checked"></use>
              </svg>Kontaktdaten</a></li>
          </ul>
      </nav>
      <div class="info">
          <p><a href="#" @click="showHotlineModal(!modalHotline)">
              <svg>
              <use xlink:href="@/assets/images/sprite.svg#icons8-ringer-volume"></use>
              </svg>Kostenlose Beratung</a></p>
          <p class="bottom-link"><span><router-link to="/impressum">Impressum</router-link>
              </span><span><router-link to="/datenschutz">Datenschutz</router-link></span><span><router-link to="/widerruf">Widerruf</router-link></span></p>
      </div>
    </aside>
</template>
<script>
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'Aside',

  methods: {
    ...mapMutations({
        showHotlineModal: 'setModalHotline'
      }),
  },
  computed: mapState({
    step: (state) => state.step,
    user: (state) => state.user,
    modalHotline: (state) => state.modalHotline
  }),
}
</script>